.custom-card {
  border-radius: 15px;
  transition: transform 0.2s, box-shadow 0.2s;
  border: 1px solid #ddd;
  background: linear-gradient(135deg, #F2F2F2, #FFFFFF);
}

.custom-card:hover {
  transform: scale(1.05);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.4);
}

.custom-card .card-title {
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
}

.custom-card .card-subtitle {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  color: #6c757d;
}

.custom-card .card-text {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  color: #333;
}

.custom-card .badge {
  font-family: 'Roboto', sans-serif;
  font-size: 0.9rem;
}

.shadow-lg {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2) !important;
}